import { getRequest } from './index'
import { SRCURL } from '@/config'

// 支付方式-查询
export const tradePayRuleStyleQuery = (settingBankId, successCallback, failureCallback) => {
  getRequest(`${SRCURL}/trade/pay/rule/style/query/${settingBankId}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
